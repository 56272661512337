<template>
  <v-card>
    <v-card-title>Account Information</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="First Name"
              class="dt-text-field"
              outlined
              hide-details
              v-model="form.first_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Last Name"
              class="dt-text-field"
              outlined
              hide-details
              v-model="form.last_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Email"
              class="dt-text-field"
              outlined
              hide-details
              v-model="form.email"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="CRM ID"
              class="dt-text-field"
              outlined
              hide-details
              v-model="form.crm_id"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              append-icon="mdi-chevron-down"
              :items="rolesList"
              item-text="name"
              item-value="slug"
              label="Roles"
              outlined
              clearable
              multiple
              hide-details
              clear-icon="mdi-close-circle-outline"
              v-model="form.role"
              return-object
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              type="password"
              label="Password"
              class="dt-text-field"
              outlined
              v-model="password"
              :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="isShowPassword ? 'text' : 'password'"
              :rules="passwordRules"
              hide-details="auto"
              @click:append="isShowPassword = !isShowPassword"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              type="password"
              label="Confirm Password"
              class="dt-text-field"
              outlined
              v-model="confirmPassword"
              :rules="confirmPasswordRules"
              @click:append="isShowConfirm = !isShowConfirm"
              :append-icon="isShowConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :type="isShowConfirm ? 'text' : 'password'"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="action == 'create'">
            <v-switch style="margin-top: 0px !important;" label="Send Email Verification" hide-details v-model="form.verified"></v-switch>
          </v-col>
        </v-row>
        <div class="d-flex justify-end my-6" flat tile>
          <v-btn
            large
            exact
            color="green darken-1"
            class="ma-1 white--text"
            @click="saveOnly"
            :loading="loadingSave"
            :disabled="loadingSave || loadingSaveAndExit"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save
          </v-btn>
          <v-btn
            large
            exact
            color="green"
            class="ma-1 white--text"
            @click="saveAndExit"
            :loading="loadingSaveAndExit"
            :disabled="loadingSave || loadingSaveAndExit"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save and Exit
          </v-btn>
          <v-btn
            large
            exact
            color="warning"
            class="ma-1 white--text"
            :to="{ name: 'dealers-account-maintenance.dealerusers.index' }"
          >
            <v-icon left>mdi-close</v-icon>
            Cancel
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  hondaPasswordRequirements,
  confirmPassword,
  minLength
} from '@/config/validations'

import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    action: {
      default: "create"
    },
    details: {
      required: false,
      default() {
        return {
          first_name: null,
          last_name: null,
          email: null,
          crm_id: null,
          role: [],
          verified: true,
        };
      },
    },
  },
  data() {
    return {
      rolesList: [
        {
            "id": 1,
            "name": "Dealer Sales",
            "slug": "dealer"
        },
        {
            "id": 5,
            "name": "Dealer Parts",
            "slug": "dealer_parts"
        },
          {
            "id": 7,
            "name": "Dealer Survey",
            "slug": "dealer_survey"
        }
      ],
      valid: true,
      loadingSave: false,
      loadingSaveAndExit: false,
      success: null,
      form: {
        first_name: null,
        last_name: null,
        email: null,
        crm_id: null,
        verified: true,
        role: [],
      },
      password: '',
      confirmPassword: '',
      isShowPassword: false,
      isShowConfirm: false,
      passwordRules: [
        (value) => (!!value || this.action != "create" ) || 'Please type password.',
        (value) => minLength(value, 10),
        hondaPasswordRequirements
      ],
      confirmPasswordRules: [
        (value) => (!!value || this.action != "create" ) || 'Please type confirm password',
        (value) => confirmPassword(value, this.password)
      ],
    };
  },
  computed: {
    ...mapGetters({
      profile: "dealerUser/GET_DEALER_USER",
    }),
  },
  mounted() {
    this.form.first_name = this.details.first_name;
    this.form.last_name = this.details.last_name;
    this.form.email = this.details.email;
    this.form.crm_id = this.details.crm_id;
    this.form.role = this.details.role;
    this.form.verified = this.details.verified;
  },
  methods: {
    ...mapActions({
      save: "dealerUser/save",
    }),
    async submit() {
      var data = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
        crm_id: this.form.crm_id,
        verified: this.form.verified,
        role: this.form.role,
        dealer_id: this.$route.params.dealer_id ? this.$route.params.dealer_id : null,
        password: this.password,
        password_confirmation: this.confirmPassword,
      };

      const id = this.$route.params.id ? this.$route.params.id : null;

      this.success = null;

      await this.save({
        id,
        data,
      }).then((data) => {
        this.loadingSave = false;
        this.loadingSaveAndExit = false;
        this.success = true;
      }).catch((e) => {
        this.success = false;
      });
    },
    async saveOnly() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loadingSave = true;
      
      await this.submit();

      if (!this.$route.params.id && this.success === true) {
        this.$refs.form.reset();
      }
    },
    async saveAndExit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loadingSaveAndExit = true;

      await this.submit();

      if (this.success === true) {
        this.$router.push({ name: "dealers-account-maintenance.dealerusers.index" });
      }
    },
  },
};
</script>

<style></style>
